import React from 'react';
import Layout from '../components/layout';
import { func, shape } from 'prop-types';
import { Grid } from '@material-ui/core';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from '../images/Backgrounds/cancel-page-background.jpg';
import { injectIntl } from 'gatsby-plugin-intl';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PageContainer from '../components/Containers/PageContainer';
import ContactCard from '../components/Cards/ContactCard';

const useStyle = makeStyles(theme => ({
  rootContainer: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
  },
  header: {
    color: COLORS.WHITE,
    fontFamily: FONTS.FAMILY.SECONDARY,
    fontSize: FONTS.SIZE.L,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.XXM,
    },
  },
  text: {
    width: '50%',
    color: COLORS.WHITE,
    marginBottom: 40,
    paddingLeft: 20,
    fontSize: FONTS.SIZE.XS,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      paddingLeft: 10,
    },
  },
}));

const Cancel = ({ intl, data }) => {
  const { formatMessage } = intl;
  const classes = useStyle();
  const t = id => formatMessage({ id });

  return (
    <Layout>
      <SEO
        title="HeadzUp Sport AB"
        description="Misslyckat köp"
        keywords={[]}
      />
      <Helmet title={data.site.siteMetadata.title} />
      <PageContainer className={classes.rootContainer}>
        <Grid container>
          <Grid item xs={12}>
            <h1 className={classes.header}>
              {t('CancelPage.title')}
            </h1>
            <p className={classes.text}>
              {t('CancelPage.text')}
            </p>
          </Grid>
          <ContactCard xs={12} sm={6} />
        </Grid>
      </PageContainer>
    </Layout>
  );
};

Cancel.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  data: shape().isRequired,
};

export default injectIntl(Cancel);

export const pageQuery = graphql`
  query CancelQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
