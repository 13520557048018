import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { string, bool, shape } from 'prop-types';
import FONTS from '../constants/fonts';
import COLORS from '../constants/colors';

const useStyles = makeStyles(theme => ({
  root: {
    color: COLORS.WHITE,
    textTransform: ({ upperCase }) =>
      upperCase ? 'uppercase' : 'none',
    fontWeight: `${FONTS.WEIGHT.NORMAL} !important`,
    fontFamily: ({ secondary }) =>
      secondary && `${FONTS.FAMILY.SECONDARY}!important`,
  },
  header: {
    fontSize: FONTS.SIZE.L,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXM,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.XM,
    },
  },
  subheader: {
    fontSize: FONTS.SIZE.XXM,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XM,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.M,
    },
  },
}));

const Header = ({
  children,
  sub,
  style,
  className,
  upperCase,
  secondary,
}) => {
  const classes = useStyles({ upperCase, secondary });

  return (
    <Typography
      style={style}
      className={`${classes.root} ${
        sub ? classes.subheader : classes.header
      } ${className}`}
      variant={sub ? 'h3' : 'h4'}
    >
      {children}
    </Typography>
  );
};

Header.propTypes = {
  children: string.isRequired,
  sub: bool,
  style: shape(),
  className: string,
  upperCase: bool,
  secondary: bool,
};
Header.defaultProps = {
  sub: false,
  style: {},
  className: '',
  upperCase: true,
  secondary: false,
};

export default Header;
