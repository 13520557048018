import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  string,
  element,
  arrayOf,
  oneOfType,
} from 'prop-types';
import colors from '../../constants/colors';
import backgroundImage from '../../images/Backgrounds/backgroundV1-min.jpg';

const useStyle = makeStyles(theme => ({
  PageContainer: {
    display: 'flex',
    flexFlow: 'column',
    padding: 100,
    paddingTop: 150,
    backgroundColor: colors.DARK_BLUE,
    background: `url(${backgroundImage}) 50% 0 no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      padding: 50,
      paddingTop: 100,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '75px 10px',
    },
  },
}));

const PageContainer = ({ children, className }) => {
  const classes = useStyle();

  return (
    <div
      className={`${classes.PageContainer} ${className}`}
    >
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  className: string,
  children: oneOfType([element, arrayOf(element)])
    .isRequired,
};

PageContainer.defaultProps = {
  className: '',
};

export default PageContainer;
