import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import {
  SUPPORT_EMAIL,
  ADDRESS,
  POSTAL_CODE_CITY_COUNTRY,
  TELEPHONE,
  YOUTUBE_LINK,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  TWITTER_LINK,
} from '../../constants/company-info';
import { Divider, Grid, Paper } from '@material-ui/core';
import { injectIntl } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core/styles';
import { shape, func } from 'prop-types';
import Header from '../Header';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(theme => ({
  ContactCard: {
    '& .MuiDivider-root': {
      backgroundColor: COLORS.WHITE,
      maxWidth: '100%',
      width: '100%',
      display: 'block',
      marginBottom: 0,
    },
    alignItems: 'center',
    display: 'flex',
    '& *': { margin: '10px 0' },
    '& .MuiPaper-root': {
      backgroundColor: `transparent`,
    },
    '& .MuiGrid-item': {
      alignItems: 'center',
      display: 'flex',
      color: COLORS.WHITE,
      '&>svg': {
        fontSize: 35,
        marginRight: 35,
      },
    },
    '& a': {
      width: 'fit-content',
      textDecoration: 'none',
      display: 'inline-block',
      '&:hover': {
        color: `${COLORS.RED}`,
      },
    },
  },
  iconsContainer: {
    marginTop: 0,
    '& >*': {
      lineHeight: '0 !important',
      marginRight: 20,
      [theme.breakpoints.down('sm')]: { marginRight: 5 },
      [theme.breakpoints.down('xs')]: { marginRight: 25 },
    },
    '& svg': {
      fontSize: 45,
      [theme.breakpoints.down(820)]: { fontSize: 30 },
      [theme.breakpoints.down('xs')]: { fontSize: 50 },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  scaledUpIcon: {
    '&>path': {
      transform: 'scale(1.15,1.3) translate(-5%, -12%)',
    },
  },
}));

const ContactCard = ({ intl, ...props }) => {
  const classes = useStyles();
  const { formatMessage } = intl;

  return (
    <Grid item {...props} className={classes.ContactCard}>
      <Paper square elevation={0}>
        <Header sub upperCase={false}>
          {formatMessage({
            id: 'ContactCard.header',
          })}
        </Header>
        <Grid container>
          <Grid item xs={12}>
            <RoomIcon />
            {/*TODO:Change address Link*/}
            <a
              href="https://www.google.se/maps/search/Ruskvädersgatan 8, 418 34 Göteborg"
              target="_blank"
              rel="noreferrer"
            >
              {ADDRESS}
              <br />
              {POSTAL_CODE_CITY_COUNTRY}
            </a>
          </Grid>
          <Grid item xs={12}>
            <PhoneIcon />
            <a href={`tel:${TELEPHONE}`}>{TELEPHONE}</a>
          </Grid>
          <Grid item xs={12}>
            <EmailIcon />
            <a href={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </a>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            item
            className={classes.iconsContainer}
          >
            <a
              href={YOUTUBE_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeIcon
                className={classes.scaledUpIcon}
              />
            </a>
            <a
              href={FACEBOOK_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href={INSTAGRAM_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href={TWITTER_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ContactCard.propTypes = {
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
};

export default injectIntl(ContactCard);
